
.loader span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background: grey;
  border-radius: 50%;
  margin: 0 2px;
  animation: loader 0.5s infinite alternate;
}

.loader span:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loader span:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    transform: translateY(0);
  }
  100% {
    width: 10px;
    height: 10px;
    opacity: 0.3;
    transform: translateY(-8px);
  }
}
