@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --radius: 1rem; /* Set your desired default radius value */
  --color-1: #0e46a3;
  --color-2: #1e0342;
  --color-3: #9ac8cd;
  --color-4: #e1f7f5;
}

.bg-color-4 {
  background-color: var(--color-4);
}

.bg-color-3 {
  background-color: var(--color-3);
}

.bg-color-2 {
  background-color: var(--color-2);
}

.bg-color-1 {
  background-color: var(--color-1);
}

/* Font Sizes */
.fs-2 {
  font-size: 2rem;
}

/* Font weight */
.fw-700 {
  font-weight: 700;
}

/*** React Select Component Start ***/

.css-1dimb5e-singleValue {
  color: white !important;
}

.css-1dimb5e-singleValue > div {
  align-items: center;
}

/*** React Select Component End***/

.container {
  padding: 0;
}

.box-shadow {
  box-shadow: 0 2px 5px #bebebe;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.delete-icon,
.record-icon {
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.delete-icon {
  width: 40px;
  height: 40px;
}

.record-icon {
  padding: 6px;
}

.recorder-hidden {
  position: absolute;
  visibility: hidden;
}
/* .delete-icon:hover {
  background-color: #ffffff;
  color: rgb(156, 4, 4);
} */

body {
  background:
  /* Draw circles in the center of a pixel to paint them */ radial-gradient(
      circle at 3.5px 3.5px,
      green 0.5px,
      transparent 0
    ),
    radial-gradient(circle at 7.5px 7.5px, green 0.5px, transparent 0);
  background-size: 8px 8px;
  padding: 0;
}

input:focus {
  outline: none;
}

.adjust-video {
  /* Replace green color with transparent */
  height: inherit;
}

.footer {
  width: 90%;
  left: 10%;
  right: 10%;
}

.border-custom {
  border: 2px solid var(--color-3);
}

.custom-color-4 {
  color: var(--color-4);
}

.adjust-avatar {
  position: absolute;
}

.custom-container {
  background: var(--color-1);
  border-radius: 20px;
  align-items: center;
  padding: 3rem 0;
}

.ai-response {
  font-size: 1.3rem;
  line-height: 3rem;
  font-weight: 500;
}

/*** CHAT BUBBLE ***/

.speech-bubble {
  padding: 8px 20px;
  background: #eeeeee;
  color: black;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.5) 0 3px 5px -3px;
  margin: 20px 0;
  font-size: 16px;
}

.speech-bubble:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 0px;
  border-color: #eeeeee;
}

.speech-bubble-left {
  margin-left: 20px;
  border-radius: 0 4px 4px 4px;
}

.speech-bubble-left:after {
  border-width: 0 20px 24px 0;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -20px;
}

.speech-bubble-right {
  margin-right: 20px;
  border-radius: 4px 0 4px 4px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.speech-bubble-right:after {
  border-width: 0 0 24px 20px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -20px;
}

.speech-bubble-bottom-right {
  margin-bottom: calc(20px + 20px);
  border-radius: 4px;
}

.speech-bubble-bottom-right:after {
  border-width: 0 24px 20px 0;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  top: auto;
  right: 20px;
  bottom: -20px;
}

.speech-bubble-bottom-left {
  margin-bottom: calc(20px + 20px);
  border-radius: 4px;
}

.speech-bubble-bottom-left:after {
  border-width: 0 0 20px 24px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  top: auto;
  left: 20px;
  bottom: -20px;
}

.speech-bubble-red {
  color: white;
  background: #F44336;
}

.speech-bubble-red:after {
  border-color: #F44336;
}

.speech-bubble-blue {
  color: white;
  background: #0e46a3;
}

.speech-bubble-blue:after {
  border-color: #0e46a3;
}

.speech-bubble-purple {
  color: white;
  background: #9c27b0;
}

.speech-bubble-purple:after {
  border-color: #9c27b0;
}

.speech-bubble-green {
  color: black;
  background: #8bc34a;
}

.speech-bubble-green:after {
  border-color: #8bc34a;
}
